<template>
  <div class="wrapper">
    <parallax
      class="section page-header header-filter header-medium"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="p-10 md-layout-item md-size-66
             md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">Enciclopedia Literaturii Romane Vechi</h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <div class="row d-flex justify-content-center">
              <div class="col-8 my-5">
              <input disabled="true" class="form-control" type="search" v-model="searchInput" :placeholder="searchPlaceholder" @keyup.enter="submitSearch" @keyup.esc="clearSearch">
            </div>
          </div>
          <div class="md-layout">
            <div class="row text-center d-flex justify-content-center">
              <button
                v-for="letter in letters"
                :key="letter.key"
                class="col-1 md-button md-round md-theme-default p-2 mx-1"
                :class="{
                  'letter-disabled': letter.count == 0,
                  'letter-enabled': letter.count > 0,
                  'md-success': letter.key == selectedLetter.key,
                  'md-white': letter.key != selectedLetter.key                  
                }"
                @click="onClickLetter(letter)"
              >
                {{ letter.key }}
              </button>
            </div>
          </div>
        </div>
        <div class="me-5">
          <div class="md-layout m-4" v-if="!showProgress">
            <div class="col-2 mx-auto">
              <p v-for="item in eliv" :key="item.id"
                class="author-name"
                :class="{
                  'clickable-author-name-selected': item.id == selectedAuthorId,
                  'clickable-author-name': item.id != selectedAuthorId}"
                  @click="onClickAuthorName(item)">{{ item.name }}
              </p>
            </div>
            <div class="col mx-5">
                <h4>{{ selectedAuthor.name }}</h4>
                <p
                  v-for="(par, pIndex) in selectedAuthor.text"
                  :key="`par-${pIndex}`"
                  v-html="par"
                ></p>
                <div v-if="selectedAuthor.writings">
                  <h6><u>Scrieri</u></h6>
                  <p
                    v-for="(writ, wIndex) in selectedAuthor.writings"
                    :key="`wrt-${wIndex}`"
                    v-html="writ"
                  ></p>
                </div>
                <div v-if="selectedAuthor.biblio">
                <h6><u>Bibliografie</u></h6>
                  <p
                    v-for="(bib, bIndex) in selectedAuthor.biblio"
                    :key="`bib-${bIndex}`"
                    v-html="bib"
                  ></p>
                </div>
            </div>
            <div v-if="selectedAuthor.images && selectedAuthor.images.length > 0" class="col-2">
              <div v-for="(image, iIndex) in selectedAuthor.images" 
                :key="`img-${iIndex}`">
                <p v-html="image"></p>
                <p class="img-caption" v-html="selectedAuthor.legends[iIndex]"></p>
            </div>
            </div>
            <!-- <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="pageSize"
              aria-controls="my-table"
            ></b-pagination> -->
          </div>
          <div class="middle" v-else>
            <vue-ellipse-progress
              loading
              :size="50"
              :progress="100"
              color="green"
              emptyColor="#a4acb3"
            />
          </div>
        </div>
      </div>
    </div>
    <VueBotUI
      :messages="chatbotData"
      :options="botOptions"
      :bot-typing="isBotTyping"
      @msg-send="messageSendHandler"
      @init="initChatbot"
      @destroy ="onBotCloseHandler"
    />
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";
import { VueBotUI } from 'vue-bot-ui';

export default {
  components: {
    VueBotUI
  },
  mixins: [Mixins.HeaderImage],
  data() {
    return {
      searchPlaceholder: "Caută",
      searchInput: "",
      isBotTyping: false,
      chatbotData: [
      ],
      botOptions: {
        botTitle: "ELIV - Chatbot",
        colorScheme: "#de9825",
        botAvatarImg: require("@/assets/img/svg/chatbot/chatbot.svg"),
      },
      botInitMessage: {
        agent: 'bot',
        type: 'text', 
        text: 'Bună! Sunt aici să îți răspund la orice întrebare bazată pe Enciclopedia Literaturii Române Vechi.',
      },
      chatSessionKey: "ELIV-chat-data",
      image: require("@/assets/img/bg-intelliv.png"),
      letters: [],
      selectedLetter: {key: "A", count: 1},
      eliv: [],
      selectedAuthor: {},
      selectedAuthorId: "",
      showProgress: true,
      // currentPage: 1,
      // pageSize: 5,
      // totalItems: 0,
    };
  },
  methods: {
    async submitSearch() {
      this.inProgress = true;
      let response = this.axios.post(`${process.env.VUE_APP_SERVER_URL}/search?corpus=eliv`, {queryText: this.searchInput});
      if (response && response.status == 200) {
        this.inProgress = false;
      }
      
    },
    clearSearch() {
      this.searchInput = ""
    },
    async messageSendHandler(message) {
      this.addMessageToChat(message.text, "user");
      this.sendMessageToAdminChatbot(message.text);
    },
    async sendMessageToAdminChatbot(message) {
      this.isBotTyping = true;
      let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/chat?corpus=eliv`, {queryText: message});
      if (response && response.status == 200) {
        this.addMessageToChat(response.data?.error ? DEFAULT_ERROR : response.data?.response, "bot");
        this.isBotTyping = false;
      }
    },
    addMessageToChat(message, type) {
      let formattedMessage = {
        agent: type,
        type: 'text', 
        text: message,
      }
      this.chatbotData.push(formattedMessage);
    },
    initChatbot() {
      let existingData = sessionStorage.getItem(this.chatSessionKey);
      if (existingData) {
        this.chatbotData = JSON.parse(existingData);
      } else {
        this.chatbotData = [this.botInitMessage];
      }
    },
    onBotCloseHandler() {
      sessionStorage.setItem(this.chatSessionKey, JSON.stringify(this.chatbotData));
      this.chatbotData = [];
    },
    async onClickLetter(letter) {
      if (letter && letter.count == 0) {
        return;
      }
      this.eliv = [];
      this.showProgress = true;
      this.selectedLetter = letter;
      // production
      this.axios.get(`${process.env.VUE_APP_SERVER_URL}/eliv/` + letter.key).then(
        response => {
          if (response && response.status == 200) {
            this.eliv = response.data;
            // this.totalItems = response.data.count;
            this.showProgress = false;
            this.selectedAuthor = response.data[0];
            if (this.selectedAuthor) {
              this.selectedAuthorId = this.selectedAuthor.id;
            }
          }
        }
      );  
    },
    onClickAuthorName(author) {
      this.selectedAuthor = author;
      this.selectedAuthorId = this.selectedAuthor.id;
    },
    getLetters() {
      this.letters = [];
      this.showProgress = true;
      this.axios.get(`${process.env.VUE_APP_SERVER_URL}/eliv-letters`).then(
        response => {
          if (response && response.status == 200) {
            this.letters = response.data;
            this.showProgress = false;
            this.selectedLetter = this.letters[0];
            this.onClickLetter(this.letters[0]);
          }
        }
      );  
    }
  },
  async mounted() {
    this.getLetters();
  },
};
</script>

<style lang="scss" scoped>
img {
  max-height: 20rem;
}

.clickable-author-name {
  text-decoration: underline;
  color: #bfc1c3;
}

.clickable-author-name-selected {
  font-weight: 700;
  color: #f94125;
}

.author-name {
  cursor: pointer;
  text-align: right;
}

.middle {
  margin: 0% 50%;
}

.md-white.letter-enabled {
  color: black !important;
}

.letter-enabled {
  cursor: pointer !important;
}

.letter-disabled {
  cursor: not-allowed !important;
}

.img-caption, .img-caption > p{
  font-size: 10px !important;
}
</style>
