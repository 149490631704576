var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('parallax',{staticClass:"section page-header header-filter header-medium",style:(_vm.headerStyle),attrs:{"parallax-active":"true"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"p-10 md-layout-item md-size-66\n           md-small-size-100 mx-auto text-center"},[_c('h1',{staticClass:"title"},[_vm._v("Enciclopedia Literaturii Romane Vechi")])])])])]),_c('div',{staticClass:"main main-raised"},[_c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row d-flex justify-content-center"},[_c('div',{staticClass:"col-8 my-5"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchInput),expression:"searchInput"}],staticClass:"form-control",attrs:{"disabled":"true","type":"search","placeholder":_vm.searchPlaceholder},domProps:{"value":(_vm.searchInput)},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submitSearch.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.clearSearch.apply(null, arguments)}],"input":function($event){if($event.target.composing){ return; }_vm.searchInput=$event.target.value}}})])]),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"row text-center d-flex justify-content-center"},_vm._l((_vm.letters),function(letter){return _c('button',{key:letter.key,staticClass:"col-1 md-button md-round md-theme-default p-2 mx-1",class:{
                'letter-disabled': letter.count == 0,
                'letter-enabled': letter.count > 0,
                'md-success': letter.key == _vm.selectedLetter.key,
                'md-white': letter.key != _vm.selectedLetter.key                  
              },on:{"click":function($event){return _vm.onClickLetter(letter)}}},[_vm._v(" "+_vm._s(letter.key)+" ")])}),0)])]),_c('div',{staticClass:"me-5"},[(!_vm.showProgress)?_c('div',{staticClass:"md-layout m-4"},[_c('div',{staticClass:"col-2 mx-auto"},_vm._l((_vm.eliv),function(item){return _c('p',{key:item.id,staticClass:"author-name",class:{
                'clickable-author-name-selected': item.id == _vm.selectedAuthorId,
                'clickable-author-name': item.id != _vm.selectedAuthorId},on:{"click":function($event){return _vm.onClickAuthorName(item)}}},[_vm._v(_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"col mx-5"},[_c('h4',[_vm._v(_vm._s(_vm.selectedAuthor.name))]),_vm._l((_vm.selectedAuthor.text),function(par,pIndex){return _c('p',{key:("par-" + pIndex),domProps:{"innerHTML":_vm._s(par)}})}),(_vm.selectedAuthor.writings)?_c('div',[_vm._m(0),_vm._l((_vm.selectedAuthor.writings),function(writ,wIndex){return _c('p',{key:("wrt-" + wIndex),domProps:{"innerHTML":_vm._s(writ)}})})],2):_vm._e(),(_vm.selectedAuthor.biblio)?_c('div',[_vm._m(1),_vm._l((_vm.selectedAuthor.biblio),function(bib,bIndex){return _c('p',{key:("bib-" + bIndex),domProps:{"innerHTML":_vm._s(bib)}})})],2):_vm._e()],2),(_vm.selectedAuthor.images && _vm.selectedAuthor.images.length > 0)?_c('div',{staticClass:"col-2"},_vm._l((_vm.selectedAuthor.images),function(image,iIndex){return _c('div',{key:("img-" + iIndex)},[_c('p',{domProps:{"innerHTML":_vm._s(image)}}),_c('p',{staticClass:"img-caption",domProps:{"innerHTML":_vm._s(_vm.selectedAuthor.legends[iIndex])}})])}),0):_vm._e()]):_c('div',{staticClass:"middle"},[_c('vue-ellipse-progress',{attrs:{"loading":"","size":50,"progress":100,"color":"green","emptyColor":"#a4acb3"}})],1)])])]),_c('VueBotUI',{attrs:{"messages":_vm.chatbotData,"options":_vm.botOptions,"bot-typing":_vm.isBotTyping},on:{"msg-send":_vm.messageSendHandler,"init":_vm.initChatbot,"destroy":_vm.onBotCloseHandler}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h6',[_c('u',[_vm._v("Scrieri")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h6',[_c('u',[_vm._v("Bibliografie")])])}]

export { render, staticRenderFns }