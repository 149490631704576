<template>
  <div class="d-flex justify-content-center">
    <highcharts :constructorType="'mapChart'" class="hc" :options="chartOptions2" ref="chart"></highcharts>
  </div>
</template>
  
<script>


export default {
  props: {
    mapData: Array,
    initializeHashMapData: Array
  },
  data() {
    return {
      unknownLocations: [],
      chartOptions2: {
        chart: {
          map: "roMap"
        },
        title: {
          text: null
        },
        subtitle: {
          text: null
        },
        mapNavigation: {
          enabled: true
        },
        legend: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            if (this.point.clusteredData) {
              let places = [];
              for (let i = 0; i < this.point.clusteredData.length; i++) {
                if (!places.includes(this.point.clusteredData[i].options.place)) {
                  places.push(this.point.clusteredData[i].options.place);
                }
              }
              return places.join("<br>");
            }
            return this.point.place;
          }
        },
        colorAxis: {
          min: 0,
          max: 20
        },
        plotOptions: {
          mappoint: {
            events: {
              click: this.onCountyClick
            },
            cluster: {
              enabled: true,
              allowOverlap: false,
              layoutAlgorithm: {
                type: 'grid',
                gridSize: 70
              },
              zones: [{
                from: 1,
                to: 4,
                marker: {
                  radius: 13,
                  fillColor: '#06271d'
                }
              }, {
                from: 5,
                to: 9,
                marker: {
                  radius: 15,
                  fillColor: '#06271d'
                }
              }, {
                from: 10,
                to: 15,
                marker: {
                  radius: 17,
                  fillColor: '#06271d'
                }
              }, {
                from: 16,
                to: 20,
                marker: {
                  radius: 19,
                  fillColor: '#06271d'
                }
              }, {
                from: 21,
                to: 100,
                marker: {
                  radius: 21,
                  fillColor: '#06271d'
                }
              }]
            }
          }
        },
        series: [
          {
            name: null,
            joinBy: 'hc-key',
            enableMouseTracking: false,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
            allAreas: true,
            showInLegend: false,
            data: this.initializeHashMapData
          },
          {
            type: 'mappoint',
            enableMouseTracking: true,
            colorKey: 'clusterPointsAmount',
            name: null,
            dataLabels: {
              enabled: false,
            },
            color: "#06271d",
            data: this.validMapData()
          }]
      }
    };
  },
  methods: {
    validMapData() {
      let validMapData = [];
      for (let data of this.mapData) {
        if (data["countyAbbr"] && data["lat"] && data["lon"]) {
          const elem = {
            name: data.title,
            place: data.place,
            lat: parseFloat(data["lat"]),
            lon: parseFloat(data["lon"]),
            country: "RO"
          };
          validMapData.push(elem);
        }
      }
      return validMapData;
    },
    onCountyClick(event) {
      if (event.point.clusteredData) {
        let places = [];
        for (let i = 0; i < event.point.clusteredData.length; i++) {
          if (!places.includes(event.point.clusteredData[i].options.place)) {
            places.push(event.point.clusteredData[i].options.place);
          }
        }
        this.$emit('countyClicked', places);
      } else {
        this.$emit('countyClicked', [event.point.options.place]);
      }
    },

  }
};
</script>
  