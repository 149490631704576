<template>
  <div class="d-flex justify-content-center">
    <highcharts class="hc" :constructorType="'mapChart'" :options="chartOptions" ref="chart" :callback="callbackChart">
    </highcharts>
  </div>
</template>
  
<script>
export default {
  props: {
    mapData: Object,
    initializeHashMapData: Array
  },
  data() {
    return {
      computedMapData: [],
      unknownLocations: [],
      chartOptions: {
        chart: {
          map: "roMap"
        },
        title: {
          text: null
        },
        subtitle: {
          text: null
        },
        mapNavigation: {
          enabled: true
        },
        legend: {
          align: 'left',
          layout: 'vertical',
          floating: true,
          events: {
            itemClick: function () {
              return false;
            }
          },
          width: 150,
          itemStyle: {
            width: 130
          },
          labelFormatter: function () {
            return this.name.length > 10 ? `<span title='${[...this.name].splice(0, 15).join('') + '...'}'>${this.name}</span>` : this.name
          },
        },
        tooltip: {
          formatter: function () {
            return `<b>${this.point.options.value}</b><br>${this.point.options.details}`;
          },
        },
        plotOptions: {
          series: {
            events: {
              show: (function () {
                var chart = this.chart,
                  series = chart.series,
                  i = series.length,
                  otherSeries;
                while (i--) {
                  otherSeries = series[i];
                  if (otherSeries != this && otherSeries.visible && otherSeries.options.type === "mapline") {
                    otherSeries.hide();
                  }
                }
              })
            },
            states: {
              inactive: {
                opacity: 1
              }
            }
          }

        },
        series: this.createAllSeries()
      }
    };
  },
  methods: {
    callbackChart(chart) {
      this.createAllMapLineSeries(chart);
    },
    createAllSeries() {
      let series = [];
      series.push({
        name: null,
        joinBy: 'hc-key',
        enableMouseTracking: false,
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
        allAreas: true,
        showInLegend: false,
        data: this.initializeHashMapData
      },);
      series.push({
        type: 'mappoint',
        enableMouseTracking: false,
        name: null,
        tooltip: null,
        animation: false,
        dataLabels: {
          format: '{point.place}'
        },
        showInLegend: false,
        data: this.validMapData()
      });

      return series;
    },
    createAllMapLineSeries(chart) {
      let colors = ["#2caffe", "#544fc5", "#00e272", "#fe6a35", "#6b8abc", "#d568fb", "#2ee0ca", "#fa4b42", "#feb56a", "#91e8e1"];
      let index = 0;
      for (let data in this.mapData) {
        chart.addSeries(this.addNewMapLineSeries(chart, data, this.mapData[data], colors[index % colors.length], index == 0), true, false);
        index++;
      }
    },
    pointsToPath(chart, fromPoint, toPoint, invertArc, curve) {
      const
        from = chart.fromLatLonToPoint(fromPoint),
        to = chart.fromLatLonToPoint(toPoint),
        arcPointX = (from.x + to.x) / (invertArc ? 2 + curve : 2 - curve),
        arcPointY = (from.y + to.y) / (invertArc ? 2 + curve : 2 - curve);

      let line = [
        ['M', from.x, from.y],
        ['Q', arcPointX, arcPointY, to.x, to.y]
      ]
      return line;

    },
    addNewMapLineSeries(chart, title, dataList, color, isDisplayed) {
      const pointList = [];

      for (let i = 0; i < dataList.length - 1; i++) {
        const from = dataList[i];
        const to = dataList[i + 1];

        if (from.lat == null || to.lat == null ||
          from.lon == null || to.lon == null ||
          from.countyAbbr == null || to.countyAbbr == null) {
          continue;
        }

        pointList.push({
          id: title,
          color,
          value: from.period + " - " + to.period,
          details: from.about + " " + from.details,
          path: this.pointsToPath(chart,
            { lat: parseFloat(from.lat), lon: parseFloat(from.lon) },
            { lat: parseFloat(to.lat), lon: parseFloat(to.lon) }, false, 0.1)
        });
      }

      return {
        name: `${title}`,
        type: 'mapline',
        lineWidth: 2,
        color,
        data: pointList,
        visible: isDisplayed,
        tooltip: {
          enabled: true,
          pointFormat: "{point.properties.NAME}: {point.properties.value}<br/>"
        },
        dataLabels: {
          enabled: false
        }
      }
    },
    validMapData() {
      let validMapData = [];
      for (let data in this.mapData) {
        for (let item of this.mapData[data]) {
          if (item["countyAbbr"] && item["lat"] && item["lon"]) {
            const elem = {
              id: item.title + item.id,
              place: item.place,
              lat: parseFloat(item["lat"]),
              lon: parseFloat(item["lon"]),
            };
            validMapData.push(elem);
          }
        }
      }

      return validMapData;
    },
  }
};
</script>

<style scoped>
.highcharts-container {
  width: 100%;
}
.highcharts-legend {
  position: absolute !important;
  left: -20px !important;
}
</style>
  