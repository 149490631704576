import Vue from "vue";
import Router from "vue-router";

// INTELLIV

import LandingPage from "./views/LandingPage.vue";
import Antologia from "./views/presentation/Antologia.vue";
import CronologiaLiteraturii from "./views/presentation/CronologiaLiteraturii.vue";
import EnciclopediaLiteraturii from "./views/presentation/EnciclopediaLiteraturii.vue";
import Harta from "./views/presentation/Harta.vue";

import MainNavbar from "./layout/MainNavbar.vue";
import MainFooter from "./layout/MainFooter.vue";

Vue.use(Router);

export default new Router({
  routes: [
    // Landing page
    {
      path: "/",
      name: "landing",
      components: {
        default: LandingPage,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true },
      },
    },
    // Enciclopedia
    {
      path: "/enciclopedie",
      name: "enciclopedie",
      components: {
        default: EnciclopediaLiteraturii,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true },
      },
    },
    // Cronologia
    {
      path: "/cronologia",
      name: "cronologia",
      components: {
        default: CronologiaLiteraturii,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true },
      },
    },
    // Antologia
    {
      path: "/antologie",
      name: "antologie",
      components: {
        default: Antologia,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true },
      },
    },
    // Harta
    {
      path: "/harta",
      name: "harta",
      components: {
        default: Harta,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true },
      },
    },
  ],
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});
