<template>
  <div class="wrapper">
    <parallax
      class="section page-header header-filter header-medium"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">Cronologia Literaturii Române Vechi</h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item mx-auto text-center">
              <div class="d-flex justify-content-end mb-3">
                <img :src="ro" alt="" class="flag me-1 cursor-pointer" @click="onClickLanguage(`ro`)">
                <img :src="en" alt="" class="flag me-5 cursor-pointer" @click="onClickLanguage(`en`)">
              </div>
              <div class="row justify-content-center">
                  <div class="col-8 my-5">
                  <input class="form-control" disabled="true" type="search" v-model="searchInput" :placeholder="searchPlaceholder" @keyup.enter="submitSearch" @keyup.esc="clearSearch">
                </div>
              </div>
              <div class="row dc-timeline m-0 mx-5">
                <nav class="dc-timeline__nav col-3">
                  <ul>
                    <li
                      v-for="item in periods"
                      :key="item"
                      @click="onClickPeriod(item)"
                      :class="{ 'dc-active': item == selectedItem }"
                    >
                      <span>{{ item }}</span>
                    </li>
                  </ul>
                </nav>
                <div class="col me-4 dc-timeline__section">
                  <div :options="{supressScrollX: true}" class="dc-wrapper">
                    <div class="row">
                      <div class="col" v-if="!inProgress" >
                        <div v-for="elem in dclr" :key="elem.id">
                          <h2 class="dc-milestone" v-html="elem.title"></h2>
                          <div>
                            <p
                              class="text-justify me-5"
                              v-for="(text, index) in elem.text"
                              :key="index"
                              v-html="getText(text)"
                            ></p>
                          </div>
                        </div>
                        </div>
                        <div class="col" v-else>
                          <div class="middle" >
                            <vue-ellipse-progress
                              loading
                              :size="50"
                              :progress="100"
                              color="green"
                              emptyColor="#a4acb3"
                            />
                          </div>
                      </div>

                      <div v-if="dclrImages.length > 0" class="col-3 d-flex flex-column justify-content-between">
                        <div
                          v-for="(elem, index) in dclrImages"
                          :key="index"
                        >
                          <img :ref="elem" :src="getFullImgSrc(elem)" alt="" @click="onClickImage(`modal-${elem}`)" class="cursor-pointer mb-3"/>
                          <!-- modal window for images -->
                          <b-modal
                            :ref="`modal-${elem}`"
                            hide-footer
                            hide-header
                          >
                            <img :src="getFullImgSrc(elem)" alt="" />
                          </b-modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <VueBotUI
      :messages="chatbotData"
      :options="botOptions"
      :bot-typing="isBotTyping"
      @msg-send="messageSendHandler"
      @init="initChatbot"
      @destroy ="onBotCloseHandler"
    />
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";
import { VueBotUI } from 'vue-bot-ui';

import "@/assets/scss/cronologie.scss";

export default {
  components: {VueBotUI},
  mixins: [Mixins.HeaderImage],
  data() {
    return {
      searchPlaceholderRo: "Caută",
      searchPlaceholderEn: "Search",
      searchPlaceholder: "Caută",
      searchInput: "",
      isBotTyping: false,
      chatbotData: [
      ],
      botOptions: {
        botTitle: "CLRV - Chatbot",
        colorScheme: "#de9825",
        botAvatarImg: require("@/assets/img/svg/chatbot/chatbot.svg"),
      },
      botInitMessage: {
        agent: 'bot',
        type: 'text', 
        text: 'Bună! Sunt aici să îți răspund la orice întrebare bazată pe Cronologia Literaturii Române Vechi.',
      },
      chatSessionKey: "CLRV-chat-data",
      image: require("@/assets/img/bg-intelliv.png"),
      ro: require("@/assets/img/svg/flags/ro.svg"),
      en: require("@/assets/img/svg/flags/uk.svg"),
      dclr: {},
      items: [],
      periods: [],
      dclrImages: [],
      selectedItem: "",
      lang: "ro",
      inProgress: false
    };
  },
  methods: {
    async submitSearch() {
      this.inProgress = true;
      let response = this.axios.post(`${process.env.VUE_APP_SERVER_URL}/search?corpus=clvr`, {queryText: this.searchInput});
      if (response && response.status == 200) {
        this.inProgress = false;
      }
      
    },
    clearSearch() {
      this.searchInput = ""
    },
    async messageSendHandler(message) {
      this.addMessageToChat(message.text, "user");
      this.sendMessageToAdminChatbot(message.text);
    },
    async sendMessageToAdminChatbot(message) {
      this.isBotTyping = true;
      let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/chat?corpus=clvr`, {queryText: message});
      if (response && response.status == 200) {
        this.addMessageToChat(response.data?.error ? DEFAULT_ERROR : response.data?.response, "bot");
        this.isBotTyping = false;
      }
    },
    addMessageToChat(message, type) {
      let formattedMessage = {
        agent: type,
        type: 'text', 
        text: message,
      }
      this.chatbotData.push(formattedMessage);
    },
    initChatbot() {
      let existingData = sessionStorage.getItem(this.chatSessionKey);
      if (existingData) {
        this.chatbotData = JSON.parse(existingData);
      } else {
        this.chatbotData = [this.botInitMessage];
      }
    },
    onBotCloseHandler() {
      sessionStorage.setItem(this.chatSessionKey, JSON.stringify(this.chatbotData));
      this.chatbotData = [];
    },
    async onClickLanguage(language) {
    if (this.lang !== language) {
      this.lang = language;
      this.searchPlaceholder = this.lang == "ro" ? this.searchPlaceholderRo : this.searchPlaceholderEn;
      await this.getDclrPeriods();
      this.getDclr();
    }
    },
    getText(text) {
      let response = "";
      if (text.hasOwnProperty("paragraph")) {
        response += text.paragraph.join(" ");
      }
      if (text.hasOwnProperty("line")) {
        response += text.line.join(" ");
      }
      return response;
    },
    getFullImgSrc(src) {
      const split = src.split('/');
      src = split[split.length - 1];
      return require(`@/assets/img/cronologie/${src}`);
    },
    onClickPeriod(item) {
      this.selectedItem = item;
      this.getDclr();
    },
    onClickImage(elemId) {
      this.$refs[elemId][0].show();
    },
    async getDclr() {
      this.inProgress = true;
      this.dclrImages = [];

      let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/dclr?period=${this.selectedItem}&lang=${this.lang}`); // production
      if (response && response.status == 200) {
        this.dclr = response.data;

        for (let item of this.dclr) {
          if ('images' in item) { // to delete after en index change
            this.dclrImages.push(...item['images']);
          }
        }

        this.inProgress = false;
      }
    },
    async getDclrPeriods() {
      let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/dclr/periods?lang=${this.lang}`); // production
      if (response && response.status == 200) {
        this.periods = Object.keys(response.data);
        this.selectedItem = this.periods[0];
      }
    },
  },
  async mounted() {
    await this.getDclrPeriods();
    this.getDclr();
  },
};
</script>

<style lang="scss" scoped>
// .title {
//   font-family: "MedievalSharp" !important;
// }

.flag {
  height: 16px;
}

.ps {
  height: 50vh;
}

.ps__thumb-x {
  visibility: hidden;
}

.dc-wrapper {
  width: 100%;

}

.image-small {
  max-width: 15vw;
}

.cursor-pointer {
  cursor: pointer;
}

.dc-timeline {
  &__nav {
    z-index: 99;
    transition: top 0.3s ease-out;

    ul {
      list-style: none;
      list-style-position: inside;
      margin: 15px 0;
      padding: 0;

      li {
        margin: 15px 0;
        padding-left: 0;
        list-style-type: none;
        color: #bfc1c3;
        cursor: pointer;
        transition: all 0.3s ease-out;

        &.dc-active {
          font-weight: bold;
          color: #f94125;
          transform: scale(1.2);
        }

        &:hover {
          color: #000;
        }
      }
    }
  }
}
</style>
